/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import bg_rectangle from "../../../assets/images/marketPlace/slelectCollectionBgr.svg";
import cardRate from "../../../assets/images/marketPlace/selectImg.png";
import checkIcon from "../../../assets/images/marketPlace/checkIcon.svg";

import { useEffect, useState } from "react";
type SelectCollectionType = {
	collectionSelected: CollectionModel;
	collections: CollectionModel[];
	onClose: () => void;
	onSelect: ({ value }: any) => void;
};

export default function SelectCollectionModal({
	onClose,
	onSelect,
	collectionSelected,
	collections,
}: SelectCollectionType) {
	const [indexSelection, setIndexSelection] =
		useState<CollectionModel | null>(null);

	useEffect(() => {
		setIndexSelection(collectionSelected);
	}, []);
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					src={bg_rectangle}
					width={350}
					alt="slibg_rectangleme"
					className={style.backgroundiMG}
				/>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<img
					src={cardRate}
					width={251}
					alt="slime"
					className={style.cardRateStyle}
				/>
				<div className={style.childStyle}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							columnGap: "30px",
						}}
					>
						<p className={style.startStyle}>All Collection</p>
						{!collectionSelected && (
							<img src={checkIcon} alt="checkIcon" />
						)}
					</div>
					{collections.map((e, i) => (
						<div key={i}>
							<div
								className={style.itemStyle}
								onClick={() => {
									setIndexSelection(e);
									onSelect(e);
									onClose();
								}}
							>
								<img src={e.Image} alt={e.Image} height={40} />
								<p
									className={
										indexSelection === e
											? style.starStyleBold
											: style.startStyle
									}
								>{`${e.CollectionName}`}</p>
								<p></p>
								{indexSelection === e ? (
									<img src={checkIcon} alt="checkIcon" />
								) : (
									<div style={{ width: "40px" }}></div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
