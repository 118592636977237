import React, { useEffect, useState } from "react";
import marketLog from "../../../../../assets/images/marketPlace/marketPlace.png";
import style from "./style.module.scss";
import SelectStarModal from "../../../../../commonsUi/modals/SelectStarMarket";
import arowBottomImg from "../../../../../assets/images/miniDropdown.png";
import SelectCollectionModal from "../../../../../commonsUi/modals/SelectCollectionModal";
import ListCardMarket from "./ListCard";
import SelectTimeModal from "../../../../../commonsUi/modals/SelectTime";
import {
	getAllMarkePlace,
	getCollectionList,
} from "../../../../../services/accountApiService";
export default function MarketPlace() {
	const [active, setActive] = useState("apt");
	const [isShowSelectStar, setShowStar] = useState(false);
	const [starSelected, setStarSelect] = useState(0);
	const [isShowSelectTime, setShowTime] = useState(false);
	const [timeSelected, setTimeSelect] = useState("1h");
	const [isShowSelectCollection, setShowCollection] = useState(false);
	const [collectionSelected, setCollectionSelect] =
		useState<CollectionModel | null>(null);
	const [collectionList, setCollectionList] = useState<
		CollectionModel[] | null
	>(null);

	const requestGetAllCollection = async () => {
		const rest: any = await getCollectionList();
		console.log({ rest });
		setCollectionList(rest.data);
		console.log({ collectionList });
	};
	useEffect(() => {
		requestGetAllCollection();
	}, []);
	return (
		<div className={style.main}>
			<img src={marketLog} alt="marketLog" width={250} />
			<div
				style={{
					display: "flex",
					width: "370px",
					justifyContent: "space-between",
					marginTop: "16px",
				}}
			>
				<div
					className={style.chooseCollectionStyle}
					onClick={() => setShowCollection(true)}
				>
					All collections
					<img src={arowBottomImg} alt="" height={20} />
				</div>
				<div
					className={style.chooseCollectionStyle}
					onClick={() => setShowStar(true)}
				>
					{`${
						starSelected === 0
							? "All stars"
							: `${starSelected} stars`
					}`}
					<img src={arowBottomImg} alt="" height={20} />
				</div>
				<div
					className={style.chooseCollectionStyle}
					onClick={() => setShowTime(true)}
				>
					{timeSelected}
					<img src={arowBottomImg} alt="" height={20} />
				</div>
				<div
					className={`${style.switch_container} ${
						active === "apt" ? style.apt_active : style.usd_active
					}`}
				>
					<div
						className={`${style.switch_item} ${
							active === "apt" ? style.active : ""
						}`}
						onClick={() => setActive("apt")}
					>
						APT
					</div>
					<div
						className={`${style.switch_item} ${
							active === "usd" ? style.active : ""
						}`}
						onClick={() => setActive("usd")}
					>
						USD
					</div>
					<div className={style.switch_button}></div>
				</div>
			</div>
			<ListCardMarket />
			{isShowSelectCollection && (
				<SelectCollectionModal
					collections={collectionList!}
					collectionSelected={collectionSelected!}
					onClose={() => {
						setShowCollection(false);
					}}
					onSelect={(value) => {
						setCollectionSelect(value as CollectionModel);
					}}
				/>
			)}
			{isShowSelectStar && (
				<SelectStarModal
					starSelected={starSelected}
					onSelect={(value) => {
						console.log({ value });
						setStarSelect(value);
					}}
					onClose={() => {
						setShowStar(false);
					}}
				/>
			)}
			{isShowSelectTime && (
				<SelectTimeModal
					timeSelected={timeSelected}
					onSelect={(value) => {
						console.log({ value });
						setTimeSelect(value);
					}}
					onClose={() => {
						setShowTime(false);
					}}
				/>
			)}
		</div>
	);
}
