import React from "react";
import styles from "./login.module.scss";
import AptosWalletConnect from "../../walletConnect";
import logo from "../../../../../assets/images/logoWebGame.png";
import logoIsland from "../../../../../assets/images/teleImage/island_login.png";
interface ILoginGame {
	fncLogin: () => void;
}

const LoginGame: React.FC<ILoginGame> = ({ fncLogin }) => {
	return (
		<div className={styles.login__container}>
			<div className={styles.logo}>
				<img src={logo} alt="logo" />
			</div>
			<div
				style={{
					marginTop: "27px",
                    padding: '0px 10px'
				}}
			>
				<p className={styles.text__welcome__top}>
					Welcome to Slime Revolution
				</p>
				<p
					className={styles.text__welcome__below}
					id="text_first_social"
				>
					The first social NFT Game build on APTOS
				</p>
			</div>
			<div
				style={{
					marginTop: "35px",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<img
					src={logoIsland}
					alt="logo island"
					className={styles.logo_island_login}
				/>
			</div>
			<div
				style={{
					marginTop: "-25px",
					
				}}
			>
				<AptosWalletConnect onClose={fncLogin} />
			</div>
		</div>
	);
};

export default LoginGame;
