import React, { useEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";

import iconCopy from "../../../../../assets/images/iconCopy.png";
import PirateIcon from "../../../../../assets/roll/modals/Pirate.png";
import accountImg from "../../../../../assets/images/teleImage/accountImg.png";
import iconUSDT from "../../../../../assets/roll/modals/USDT.png";
import emblem from "../../../../../assets/images/teleImage/emblem.png";
import emblem1 from "../../../../../assets/images/teleImage/emblem1.png";
import { InfoData, ResUrl } from "../../../../../lib/models/api";
import iconAccount from "../../../../../assets/images/teleImage/icon_account.png";
import iconProfile from "../../../../../assets/images/common/iconProfile.png";
import iconCopyNew from "../../../../../assets/images/teleImage/icon_copy.png";
import iconCoin from "../../../../../assets/images/teleImage/icon_coin.png";
import iconUSDTNew from "../../../../../assets/images/teleImage/USDT.png";
import baseWallet from "../../../../../assets/images/teleImage/baseWallet.png";
import iconETH from "../../../../../assets/images/common/aptosLogo.png";
import aptos_Logo from "../../../../../assets/images/aptos_Logo.png";
import swap_icon from "../../../../../assets/images/account/swap.png";
import top_icon from "../../../../../assets/images/account/top_up.png";
import transfer_icon from "../../../../../assets/images/account/transfer.png";

import {
	renderBtnType,
	RenderTextFieldType,
} from "../../../../../lib/types/button";
import {
	apiGetMyAccount,
	requestUpdateProfile,
	startWithDraw,
	withdrawTimeInfo,
} from "../../../../../services/accountApiService";
import ChoosePlanFarming from "../../../../../commonsUi/modals/choosePlanFarming";
import ButtonClaim from "../../../../../commonsUi/buttons/buttonClaim";
import ModalRewardUSDT from "../../../../../commonsUi/modals/modalRewardUSDT";
import { writeClipboardText } from "../../../../../utils/function/text";
import WalletConnect from "../../walletConnect";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import ModalWithDrawUSDT from "../../../../../commonsUi/modals/modalWithDrawUSDT";
import { stavaxAccount } from "../../../../../connectStavax";
import { useAccount, useDisconnect, useBalance } from "wagmi";
import ButtonRectangle from "../../../../../commonsUi/buttons/buttonRectangle";
import ModalConfirmTSPC from "../../../../../commonsUi/modals/ModalConfirmtSPC";
import ModalDeposit from "../../../../../commonsUi/modals/ModalDeposit";
import ReachUSDTModal from "../../../../../commonsUi/modals/reachUstd";
import ModalConnectWallet from "../../../../../commonsUi/modals/ConnectWalletModal";
import {
	getLocalKeylessAccount,
	logOutKeylessAccount,
} from "../../../../../utils/aptos/aptosStorage";
import { useKeylessAccounts } from "../../../../../core/useKeylessAccounts";
import { collapseAddress } from "../../../../../core/utils";
import { devnetClient } from "../../../../../core/constants";
import { convertOctasToApt } from "../../../../../aptosConfig";
import SwapCoinModal from "../../../../../commonsUi/modals/SwapCoin";

import TransferCoinModal, {
	CallbackCoin,
} from "../../../../../commonsUi/modals/TransferCoin";
import TransferCoinSuccessModal from "../../../../../commonsUi/modals/TransferCoinSuccess";
import AptosWalletConnect from "../../walletConnect";
export const formatAddress = (addr: any): string => {
	const start = addr.slice(0, 10);
	const end = addr.slice(-3);
	return `${start}...${end}`;
};
type AccountAppProps = {};

const AccountPage: React.FC<AccountAppProps> = ({}) => {
	const [showCopy, setShowCopy] = useState<boolean>(false);
	const [showCopy1, setShowCopy1] = useState<boolean>(false);
	const [resUrl, setResUrl] = useState<ResUrl>();
	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [infoData, setInfoData] = useState<InfoData | null>(null);
	const { disconnect: disconnectWagmi, disconnectAsync } = useDisconnect();
	const [isShowInvite, setShowInvite] = useState<boolean>(false);
	const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
	const [showPopupUSDT, setShowPopupUSDT] = useState<boolean>(false);
	const [showPopupAsk, setShowPopupAsk] = useState<boolean>(false);
	const [isShowWithDraw, setShowWithDraw] = useState<boolean>(false);
	const [timeClickWithDraw, setTimeClickWithDraw] = useState<number>(0);
	const [isShowPopupTSPC, setIsShowPopupTSPC] = useState<boolean>(false);
	const [isShowPopupDeposit, setShowPopupDeposit] = useState<boolean>(false);
	const [isShowAlert, setShowAlert] = useState<boolean>(false);
	const [isWithdrawSuccess, setWithDrawsuccess] = useState<boolean>(false);
	const shareRef = useRef<HTMLDivElement | null>(null);
	// const { connect, connectors } = useConnect<Config, unknown>();
	const { activeAccount, disconnectKeylessAccount } = useKeylessAccounts();
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const [accountIsConnect, setConnectWalletAccount] = useState(false);
	const [isShowReachUSDDT, setReachUSDT] = useState(false);
	const [isShowTransferModal, setShowTransferModal] = useState(false);
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const [ethToUSDT, setEthToUsdt] = useState(0);
	const [cointApt, setCoinApt] = useState(0);
	const [cointApt1, setCoinApt1] = useState(0);
	const [address, setAddress] = useState<string>("");
	const [amount, setAmount] = useState<number>(0);
	const [transactionFee, setTransactionFee] = useState<string>("");
	const [hash, setHash] = useState<string>("");
	const accountWagmi = useAccount();
	const result = useBalance({
		address: accountWagmi!.address,
	});
	const getAptosClient = getLocalKeylessAccount();
	const RenderBtn = ({ isEnable, title, onClick }: renderBtnType) => {
		return (
			<div
				className={`${
					isEnable ? styles.enableBtnStyle : styles.disableBtnStyle
				} active-btn`}
				onClick={onClick}
			>
				{title}
			</div>
		);
	};
	const requestStartWithDraw = async () => {
		const resStartWithDraw = await startWithDraw();
	};
	const requestWithdrawTimeInfo = async () => {
		return await withdrawTimeInfo();
	};

	const handleCallApiPillageJourneyInfoAgain = async (): Promise<void> => {
		const responsePillageJourneyInfo: any = await apiGetMyAccount();
		if (responsePillageJourneyInfo?.success) {
			setInfoData(responsePillageJourneyInfo.data);
			console.log({ responsePillageJourneyInfo });
		} else {
		}
	};

	useEffect(() => {
		if (
			(getAptosClient && getAptosClient?.accountAddress) ||
			activeAccount
		) {
			setConnectWalletAccount(true);
		} else {
			setConnectWalletAccount(false);
		}
	}, [accountIsConnect, activeAccount, getAptosClient]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAlert(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isShowAlert]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setWithDrawsuccess(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isWithdrawSuccess]);
	const handleShowPopupUSDT = async () => {
		await requestStartWithDraw();
		const resWithdrawTimeInfo: any = await requestWithdrawTimeInfo();
		if (resWithdrawTimeInfo) {
			if (new Date(resWithdrawTimeInfo.data.TimeWithDraw) <= new Date()) {
				// setShowWithDraw(true);
				setShowAlert(true);
				return;
			}

			setTimeClickWithDraw(
				new Date(resWithdrawTimeInfo.data.TimeWithDraw).getTime()
			);
		}
		setShowPopupUSDT(true);
	};

	const onClickButtonQuickUSDT = () => {
		setShowPopupUSDT(false);
		setShowInvite(true);
	};
	const handleClosePopupUSDT = () => {
		setShowPopupUSDT(false);
	};

	const ethBalance = useBalance({
		address: accountWagmi?.address,
	});

	const getCointApt = async () => {
		const accountCoinsData: any = await devnetClient.getAccountCoinsData({
			accountAddress: activeAccount
				? activeAccount.accountAddress.toString()
				: getAptosClient!.accountAddress.toString(),
		});
		if (accountCoinsData.length > 0) {
			setCoinApt(accountCoinsData[0].amount);
			const bnbUsdtPriceResponse = await fetch(
				"https://api.binance.com/api/v3/ticker/price?symbol=APTUSDT"
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			setCoinApt1(Number(bnbUsdtPriceData.price));
			console.log({ bnbUsdtPriceData });
			const value =
				Number(accountCoinsData[0].amount) *
				convertOctasToApt(bnbUsdtPriceData.price);
			setEthToUsdt(value);
			console.log({ value });
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCopy1(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, [showCopy1]);

	useEffect(() => {
		if (!hasMounted) {
			handleCallApiPillageJourneyInfoAgain();
			setHasMounted(true);
		}
	}, [hasMounted]);

	useEffect(() => {
		const handleUpdateAccount = async (): Promise<any> => {
			if (getAptosClient && getAptosClient!.accountAddress.toString()) {
				await requestUpdateProfile(
					getAptosClient!.accountAddress.toString(),
					"Aptos"
				);
			}
		};
		handleUpdateAccount();
	}, [getAptosClient]);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);

	useEffect(() => {
		if (window.Telegram && window.Telegram.WebApp) {
			window.Telegram.WebApp.ready();
		}
	}, []);

	const RenderTextField = ({
		placeHolder,
		title,
		isButton = true,
		btnTile,
		isEnable,
		onClick,
		onClickText,
	}: RenderTextFieldType) => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "self-start",
				}}
			>
				<p className={styles.titleStyle}>{title}</p>
				<div className={styles.inputStyle1}>
					<p
						onClick={onClickText}
						style={{
							width: "58vw",
							overflow: "hidden",
							textAlign: "start",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						{placeHolder}
					</p>
					{isButton ? (
						<RenderBtn
							onClick={onClick}
							title={btnTile}
							isEnable={isEnable}
						></RenderBtn>
					) : (
						<>
							<img
								onClick={() => {
									setShowCopy(true);
									writeClipboardText(resUrl?.userId);
								}}
								src={iconCopy}
								alt="iconCopy"
								style={{
									marginRight: "10px",
									width: "30px",
									height: "30px",
									marginTop: "10px",
								}}
							/>
							{showCopy && (
								<div className={styles.copyStyle}>Copied</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	};

	const RenderBody = () => {
		return (
			<>
				<div className={styles.cardTileStyle}>Wallet</div>
				<div className={styles.bodyHeader}>
					<div className={styles.wallet__connect}>
						<div className={styles.wallet__left}>
							{(getAptosClient || activeAccount) &&
							accountIsConnect ? (
								<p
									onClick={() => {
										setShowCheckIn(true);
										writeClipboardText(
											getAptosClient!.accountAddress.toString()
										);
									}}
								>
									Address:{" "}
									{collapseAddress(
										activeAccount
											? activeAccount.accountAddress.toString()
											: getAptosClient!.accountAddress.toString()
									)}
								</p>
							) : (
								<div
									onClick={() => setConnectWallet(true)}
									className={styles.item__account__withdraw}
								>
									Connect wallet
								</div>
							)}
							{(getAptosClient || activeAccount) &&
								accountIsConnect && (
									<div
										onClick={() => {
											localStorage.removeItem(
												"@aptos/account"
											);
											disconnectKeylessAccount();
											logOutKeylessAccount();
											setConnectWalletAccount(false);
										}}
										className={
											styles.item__account__withdraw
										}
									>
										Disconnect
									</div>
								)}
						</div>
					</div>
					{(getAptosClient || activeAccount) && (
						<div className={styles.mainActionStyle}>
							<div
								className={styles.bodyActionStyle}
								onClick={() => setShowPopupDeposit(true)}
							>
								<img
									src={top_icon}
									alt="top_icon"
									className="active-btn"
									width={50}
									height={50}
								/>
								<p className={styles.actionTextStyle}>Top up</p>
							</div>
							<div
								className={styles.bodyActionStyle}
								onClick={() => setShowAlert(true)}
							>
								<img
									src={swap_icon}
									alt="top_icon"
									width={50}
									className="active-btn"
								/>
								<p className={styles.actionTextStyle}>Swap</p>
							</div>
							<div
								className={styles.bodyActionStyle}
								onClick={() => setShowTransferModal(true)}
							>
								<img
									src={transfer_icon}
									alt="top_icon"
									width={50}
									className="active-btn"
								/>
								<p className={styles.actionTextStyle}>
									Transfer
								</p>
							</div>
						</div>
					)}
					<div className={styles.wallet__eth}>
						<img
							src={iconETH}
							style={{
								marginLeft: "6px",
							}}
							alt="icon eth"
							width={27}
							height={27}
						/>
						<div className={styles.coinStyle}>
							<div>
								<p className={styles.titleAptos}>
									APT Balance:
								</p>
								<p>{`(~$${cointApt1.toFixed(2)})`} </p>
							</div>
							<div>
								<p className={styles.titleAptos}>
									{convertOctasToApt(cointApt).toFixed(2)}
									{" APT"}
								</p>
								<p>{`(~$${ethToUSDT.toFixed(2)})`} </p>
							</div>
						</div>
					</div>

					<div>
						<h3 className={styles.title__wallet}>
							Top up your account
						</h3>
						<p className={styles.title__content}>
							Fund your wallet with APT to make purchases.
						</p>
					</div>
				</div>
			</>
		);
	};
	useEffect(() => {
		setResUrl({
			userId: localStorage.getItem("_userId"),
		});
	}, []);
	useEffect(() => {
		// buyItem();
		if (getAptosClient || activeAccount) {
			getCointApt();
		}
	}, [getAptosClient, activeAccount]);

	useEffect(() => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.ready();
		}
	}, []);
	const RenderHeader = () => {
		return (
			<>
				{/* <button
					onClick={() => {
						if (window.Telegram && window.Telegram.WebApp) {
							const telegramLink = "https://accounts.google.com/o/oauth2/v2/auth";
							window!.Telegram!.WebApp!.openLink(telegramLink, {
								try_instant_view: true,
							});
						} else {
							console.log("ERROR");
						}
					}}
				>
					click
				</button> */}
				<div
					style={{
						width: "200px",
						display: "flex",
						marginLeft: "106px",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: "30px",
					}}
				>
					<img
						src={accountImg}
						alt="accountImg"
						style={{ width: "66vw", height: "44px" }}
					/>
				</div>
				<div className={styles.cardTileStyle}> Game Account</div>
				<div className={styles.bodyHeader}>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconProfile}
								className={styles.item__account__icon}
								alt="icon account"
							/>
							<p className={styles.item__account__text}>
								User name: {`${infoData?.actort.Username}`}
							</p>
						</div>
					</div>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconAccount}
								className={styles.item__account__icon}
								alt="icon account"
							/>
							<p className={styles.item__account__text}>
								UID: {`${infoData?.actort.UserId}`}
							</p>
						</div>
						<img
							src={iconCopyNew}
							alt="icon copy"
							className={styles.item__account__icon__copy}
							onClick={async () => {
								setShowCheckIn(true);
								writeClipboardText(
									infoData?.actort.UserId.toString()
								);
							}}
						/>
					</div>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconCoin}
								className={styles.item__account__icon}
								alt="icon crystal"
							/>
							<p className={styles.item__account__text}>
								{(infoData?.SpcInChest !== undefined &&
									Math.floor(infoData?.SpcInChest)) ||
									0}{" "}
								tSPC
							</p>
						</div>
						<span
							id="button"
							className={styles.item__account__withdraw}
							onClick={() => setIsShowPopupTSPC(true)}
						>
							Withdraw
						</span>
					</div>
					<div className={styles.item__account}>
						<div className={styles.item__account__left}>
							<img
								src={iconUSDTNew}
								className={styles.item__account__icon}
								alt="icon USDT"
							/>
							<p className={styles.item__account__text}>
								{`${
									infoData?.Usdt != null
										? Number(infoData?.Usdt).toFixed(2)
										: 0
								}`}{" "}
								$
							</p>
						</div>
						<span
							id="button"
							className={styles.item__account__withdraw}
							onClick={
								infoData?.Usdt != null && infoData?.Usdt >= 5
									? handleShowPopupUSDT
									: () => {
											setReachUSDT(true);
									  }
							}
						>
							Withdraw
						</span>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className={styles.main}>
			<RenderHeader />
			<RenderBody />
			{isShowConnectWallet && (
				<AptosWalletConnect
					isPreload={false}
					onClose={() => {
						setConnectWallet(false);
					}}
				/>
			)}
			{address !== "" && (
				<TransferCoinSuccessModal
					onClose={() => {
						setAddress("");
						setAmount(0);
						setTransactionFee("");
						setHash("");
						console.log(address);
						getCointApt();
					}}
					address={address}
					amount={amount}
					transactionFee={transactionFee}
					hashTxt={hash}
				/>
			)}
			{isShowTransferModal && (
				<TransferCoinModal
					onClose={() => {
						setShowTransferModal(false);
					}}
					onCallback={(
						address: string,
						amount: number,
						transactionFee: string,
						hash: string
					) => {
						setShowTransferModal(false);
						setAddress(address);
						setAmount(amount);
						setTransactionFee(transactionFee);
						setHash(hash);
						getCointApt();
					}}
				/>
			)}
			{showCheckIn && <div className={styles.notice1}>{`copied`}</div>}
			{isShowReachUSDDT && (
				<ReachUSDTModal
					onClose={() => {
						setReachUSDT(false);
					}}
				/>
			)}
			{isShowInvite && (
				<ChoosePlanFarming
					iconPopup={PirateIcon}
					onClose={() => setShowInvite(false)}
				>
					<div className={styles.wrapper}>
						<p className={styles.confirmPurchaseTitle}>
							Ask 5 friends for support to verify quickly
						</p>
						<div>
							<div className={styles.copyMainStyle}>
								<img
									onClick={() => {
										setShowCopy1(true);
										writeClipboardText(
											`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId} \n\nPlay-to-Airdrop! Start your journey to earn tSPC. The more tSPC you get, the bigger your Airdrop. Earn now!`
										);
									}}
									src={iconCopy}
									alt="iconCopy"
									style={{
										width: "30px",
										height: "30px",
										marginTop: "10px",
										position: "absolute",
										right: "4px",
									}}
								/>
								<div className={styles.classRefer}>
									<p className={styles.referLinkStyle}>
										Your referral link
									</p>
									<p
										className={styles.desReferLink}
									>{`https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`}</p>
								</div>
								{showCopy1 && (
									<div className={styles.copyStyle1}>
										Copied
									</div>
								)}
							</div>
						</div>
						<div className={styles.button_popup_add_now}>
							<div>
								<ButtonClaim
									backGround={"bgc_button_up_level"}
									onClickButton={() => {
										setShowInvite(false);
										if (shareRef.current) {
											shareRef.current.click();
										}
									}}
								>
									<p className={styles.button_ask_now}>
										Ask now
									</p>
								</ButtonClaim>
								<p
									className={styles.text_invite_count}
								>{`*Need at least 5 friends to verify (${
									infoData?.invitedCount || 0
								}/5)`}</p>
							</div>
						</div>
					</div>
				</ChoosePlanFarming>
			)}
			<RWebShare
				data={{
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: `https://t.me/Slime_Revolution_bot?start=${resUrl?.userId}`,
				}}
				onClick={() => console.log("shared successfully!")}
			>
				<p ref={shareRef}></p>
			</RWebShare>
			{showPopupUSDT && (
				<ModalRewardUSDT
					onClickButtonQuickUSDT={onClickButtonQuickUSDT}
					onClosePopup={handleClosePopupUSDT}
					timeCountDown={timeClickWithDraw}
					onClickWithDraw={() => {
						setShowPopupUSDT(false);
						setShowWithDraw(true);
					}}
				/>
			)}
			{/* {isShowWithDraw && (
				<ModalWithDrawUSDT
					onWithdrawSuccess={() => {
						setWithDrawsuccess(true);
					}}
					onClosePopup={() => {
						setShowWithDraw(false);
						handleCallApiPillageJourneyInfoAgain();
					}}
					title="You can claim $5 USDT!"
					des="Congratulation! Your account is verified and eligible for withdrawal."
				/>
			)} */}
			{isShowConnectWalelt && (
				<AptosWalletConnect
					onClose={() => setShowConnectWallet(false)}
					isPreload={false}
				/>
			)}
			{isShowPopupTSPC && (
				<ModalConfirmTSPC onClose={() => setIsShowPopupTSPC(false)} />
			)}
			{isWithdrawSuccess && (
				<div className={styles.notice2}>{`Successful withdrawal`}</div>
			)}
			{isShowPopupDeposit && (
				<ModalDeposit
					addressWallet={getAptosClient!.accountAddress.toString()}
					onClose={() => setShowPopupDeposit(false)}
				/>
			)}
			{isShowAlert && (
				<div className={styles.notice1}>{`Coming soon`}</div>
			)}
		</div>
	);
};

export default AccountPage;
