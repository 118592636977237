/** @format */

import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import Mysterycard from "../../../assets/images/card/Mysterycard.png";
import InfoImg from "../../../assets/images/ranking/inFoIcon.png";
import earnImg from "../../../assets/images/card/earnImg.png";
import gasSponsor from "../../../assets/images/card/gasSponsor.png";
import Countdown from "react-countdown";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import { number } from "zod";
import { getAptosClient } from "../../../components/page/TeleAppPage/walletConnect";
import CardLevelInfoModal from "../CardLevelInfo";
import { useState } from "react";
type CardMBeforeMintModalType = {
	onClose: () => void;
	ExpiredTimeMint: string;
	cardLevel: number;
	onClaim: () => void;
	onConnectWallet: () => void;
};

export default function CardBeforeMintModal({
	onClose,
	ExpiredTimeMint,
	cardLevel,
	onClaim,
	onConnectWallet,
}: CardMBeforeMintModalType) {
	const [isShowInfo, setShowInfo] = useState(false);
	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<p className={style.card_level}>Card Level: {cardLevel}</p>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						columnGap: "4px",
						marginBottom: "10px",
					}}
				>
					<p className={style.cardRateStyle}>Card rate</p>
					<img
						src={InfoImg}
						width={20}
						alt="InfoImg"
						onClick={() => setShowInfo(true)}
					/>
				</div>
				<img src={Mysterycard} alt="Mysterycard" height={150} />
				<div
					style={{
						display: "flex",
						alignItems: "center",
						marginBottom: "20px",
					}}
				>
					<p className={style.expiredTime}>{`Expired time:  `} </p>
					<Countdown
						className={style.expiredTime}
						date={Number(new Date(ExpiredTimeMint).getTime())}
						onComplete={() => {}}
					/>
				</div>
				<img src={earnImg} alt="earnImg" width={280} />
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				<div
					style={{
						paddingTop: "90px",
						paddingBottom: "4px",
					}}
				>
					<CircleButton title={"Claim card"} onTap={onClaim} />
				</div>
				<img src={gasSponsor} alt="gasSponsor" width={200} />
				{isShowInfo && (
					<CardLevelInfoModal
						onClose={() => {
							setShowInfo(false);
						}}
						cardLevel={cardLevel}
					/>
				)}
			</div>
		</div>
	);
}
