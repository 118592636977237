/** @format */

import style from "./index.module.scss";
import cardImg from "../../../assets/images/teleImage/brgCard.png";
import tagImg from "../../../assets/images/teleImage/tagCard.png";
import buttonCancel from "../../../assets/images/back_icon.png";
import avtImg from "../../../assets/roll/card/Avt.png";
import Reward30 from "../../../assets/images/teleImage/Reward30.png";

import backImg from "../../../assets/images/card/BackImg.png";
import nextImg from "../../../assets/images/card/NextImg.png";
import imgT from "../../../assets/roll/card/imgT.png";
import { useEffect, useState } from "react";
import { collectionsById } from "../../../services/accountApiService";
import GameButton from "../../commonButton/gameButton/GameButton";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import SellCardModal from "../ModalSellCard";
import CardMarketPage from "../CardMarket";
import { convertOctasToApt } from "../../../aptosConfig";
import StarRating from "../ModalBurnCard/StartRating";

type ModalCardInfoType = {
	cards: CardModal[];
	indexSelectCard: number;
	onClose: () => void;
	onUpdate: () => void;
};

export default function ModalCardInfo({
	cards,
	onClose,
	onUpdate,
	indexSelectCard,
}: ModalCardInfoType) {
	const [isEnable, setEnable] = useState(0);
	const [indexSelected, setIndexSelected] = useState(0);
	const [isShowSellCard, setShowCard] = useState(false);
	const [isShowMarketPlace, setShowMarketPlace] = useState(false);

	useEffect(() => {
		setIndexSelected(indexSelectCard);
	}, []);
	const StatsCard = () => {
		return (
			<div className={style.statsCard}>
				<div className={style.statsItem}>
					<span>Total Volume:</span>
					<span>
						<strong>{`${cards![
							indexSelected
						].info_market.total_volume.toFixed(2)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Floor Price:</span>
					<span>
						<strong>{`${cards![
							indexSelected
						].info_market.floor_price.toFixed(2)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Highest Price:</span>
					<span>
						<strong>{`${cards![
							indexSelected
						].info_market.highest.toFixed(2)} APT`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Listed:</span>
					<span>
						<strong>{`${cards![
							indexSelected
						].info_market.listed.toFixed(2)} %`}</strong>
					</span>
				</div>
				<div className={style.statsItem}>
					<span>Unique Owner:</span>
					<span>
						<strong>{`${
							cards![indexSelected].info_market.unique_own
						} `}</strong>
					</span>
				</div>
			</div>
		);
	};

	const RenderAmount = () => {
		return (
			<div className={style.amountStyle}>
				<p className={style.amountTextStyle}>+1</p>
			</div>
		);
	};

	return (
		<div className={style.modalOverlay}>
			<div className={style.main}>
				<img
					onClick={onClose}
					src={buttonCancel}
					width={171}
					alt="slime"
					className={`${style.closeBtn} active-btn`}
				/>
				{cards && (
					<div className={style.body}>
						<img
							src={backImg}
							alt="backImg"
							className="active-btn"
							width={50}
							onClick={() => {
								if (indexSelected === 0) {
									setIndexSelected(cards.length - 1);
									return;
								}
								setIndexSelected(indexSelected - 1);
							}}
						/>
						<div className={style.itemCard}>
							<div className={style.starStyle}>
								<StarRating
									size={30}
									count={cards![indexSelected].Star}
								/>
							</div>
							<img
								src={cards![indexSelected].CardImage}
								alt={`slime-${cards![indexSelected]}`}
								className={
									cards![indexSelected].success === 1
										? style.image1
										: style.imageDisable1
								}
							/>
							<div className={style.startStyle}> </div>
							<RenderAmount />
						</div>
						<img
							className="active-btn"
							width={50}
							src={nextImg}
							alt="nextImg"
							onClick={() => {
								if (cards!.length - 1 === indexSelected) {
									setIndexSelected(0);
									return;
								}
								setIndexSelected(indexSelected + 1);
							}}
						/>
					</div>
				)}
				<StatsCard />

				{isShowSellCard && (
					<SellCardModal
						onUpdate={onUpdate}
						cardId={cards![indexSelected].Id}
						onClose={() => setShowCard(false)}
						iconPopup={cards![indexSelected].CardImage}
					/>
				)}
				<div
					style={{
						height: "20px",
					}}
				></div>
				{isShowMarketPlace && (
					<CardMarketPage
						onUpdate={onUpdate}
						onClose={() => setShowMarketPlace(false)}
						id={cards![indexSelected].Id}
					/>
				)}
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						padding: "0 16px",
						width: "100%",
					}}
				>
					{cards![indexSelected].success === 1 ? (
						<p
							onClick={() => {
								setShowCard(true);
							}}
							className={style.buySellStyle}
						>
							Sell this card
						</p>
					) : (
						<p></p>
					)}
					<p
						onClick={() => {
							setShowMarketPlace(true);
						}}
						className={style.buySellStyle}
					>
						Buy more now
					</p>
				</div>
			</div>
		</div>
	);
}
