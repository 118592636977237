import iconShieldActive from "../../assets/roll/shield_Active.png";
import iconShieldInActive from "../../assets/roll/shield_inActive.png";

import Home from "../../assets/images/teleImage/Home.png";
import Attack from "../../assets/images/teleImage/Attack.png";
import Quest from "../../assets/images/teleImage/Quest.png";
import AccountInActive from "../../assets/images/teleImage/Ranking.png";
import Shop from "../../assets/images/teleImage/Shop.png";
import ShopActive from "../../assets/images/teleImage/ShopActive.png";
import AccountActive from "../../assets/images/teleImage/RankingActive.png";
import QuestActive from "../../assets/images/teleImage/QuestActive.png";
import Ranking from "../../assets/images/ranking/Ranking.png";
import RankingActive from "../../assets/images/ranking/RankingInactive.png";
import HomeInActive from "../../assets/images/teleImage/HomeActive.png";
import AttackActive from "../../assets/images/teleImage/AttackActive.png";
import IsLandActive from "../../assets/images/ranking/AttackActive.png";
import IsLandInactive from "../../assets/images/ranking/Attack.png";
import CardInActive from "../../assets/images/card/CardInActive.png";
import CardActive from "../../assets/images/card/CardActive.png";
import MarketActive from "../../assets/images/marketPlace/MarketActive.png";
import MarketInactive from "../../assets/images/marketPlace/MarketInActive.png";

import QrCode from "../../assets/images/teleImage/QrCode.png";

const listDataShield = [iconShieldActive, iconShieldActive, iconShieldInActive];
export const getRandomString = (length: number) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters[randomIndex];
	}
	return result;
};

const tabData = [
	// {
	//     id: 0,
	//     imgActive: Home,
	//     imgInActive: HomeInActive,
	//     renderComponent: (
	//         <StartFlowComponent
	//             dataRenderHeader={dataRenderHeader}
	//             setDataRenderHeader={setDataRenderHeader}
	//             setIsClient={setIsClient}
	//             screen={3}
	//             setScreen={setScreen}
	//             isFirstOpen={() => {
	//                 setIndexTab(2);
	//                 setIsJoinPillage(true);
	//             }}
	//         />
	//     ),
	// },

	{
		id: 1,
		imgActive: QuestActive,
		imgInActive: Quest,
		renderComponent: "",
	},
	{
		id: 2,
		imgActive: CardActive,
		imgInActive: CardInActive,
		renderComponent: "",
	},
	{
		id: 0,
		imgActive: IsLandActive,
		imgInActive: IsLandInactive,
	},
	{
		id: 3,
		imgActive: Ranking,
		imgInActive: RankingActive,
		renderComponent: "",
	},

	// {
	// 	id: 2,
	// 	imgActive: ShopActive,
	// 	imgInActive: Shop,
	// },
	{
		id: 4,
		imgActive: AccountActive,
		imgInActive: AccountInActive,
		renderComponent: "",
	},
];

export { listDataShield, tabData };
