import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import MetamaskImg from "../../../assets/images/common/MetamaskImg.png";
import TonImg from "../../../assets/images/common/TonImg.png";
import AptosLogo from "../../../assets/images/aptos_Logo.png";
import walletIcon from "../../../assets/walletIcon.png";
import {
	Locales,
	useTonConnectUI,
	TonConnectButton,
} from "@tonconnect/ui-react";
import { useTonAddress } from "@tonconnect/ui-react";
import { decodeJwt, encodeJwt, tonToNanoton } from "../../../utils/extension";
import { paymentRequest } from "../../../services/accountApiService";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import {
	clientAptos,
	convertAptToOctas,
	convertOctasToApt,
} from "../../../aptosConfig";
import { devnetClient } from "../../../core/constants";
import { getAptosClient } from "../TeleAppPage/walletConnect";
import { APTOS_COIN } from "@aptos-labs/ts-sdk";
import InsufficientModal from "../../../commonsUi/modals/InsufficientCoin";
import ModalCoppyAddress from "../../../commonsUi/modals/ModalCopyAddress";
import { formatAddress } from "../TeleAppPage/components/Account";
import LoadingCommon from "../../../commonsUi/CommonUI/LoadingApp";
type PaymentAptosAndTonType = {
	onClose: () => void;
	isBUY_LEGACY: boolean;
	dataPros: string;
};
export default function PaymentAptosAndTon({
	onClose,
	isBUY_LEGACY,
	dataPros,
}: PaymentAptosAndTonType) {
	const userFriendlyAddress = useTonAddress();
	const [tonConnectUI, setOptions] = useTonConnectUI();
	const [insufficientCoin, setInsufficientCoin] = useState(false);
	const [isShowLoading, setShowLoading] = useState(false);

	useEffect(() => {
		if (userFriendlyAddress !== "") {
			sendTransaction();
		}
	}, [userFriendlyAddress]);

	const sendTransaction = async () => {
		const bnbUsdtPriceResponse = await fetch(
			"https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT"
		);
		const dataDecode: any = await decodeJwt(dataPros!);
		const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
		try {
			const hash = await tonConnectUI.sendTransaction({
				validUntil: Math.floor(Date.now() / 1000) + 60,
				messages: [
					{
						address:
							"UQBkkD_ESQA64FNXlCQve2673XcCgx7gm4h9rBfewBtEvKcj",
						amount: `${tonToNanoton(
							dataDecode!.Price! / bnbUsdtPriceData.price
						).toFixed(0)}`,
					},
				],
			});
			var iframe: any = document.getElementById("isLandIframe");
			if (isBUY_LEGACY) {
				iframe.contentWindow.postMessage(
					{ type: "BOUGHT_LEGACY", data: hash.boc },
					"*"
				);
			} else {
				iframe.contentWindow.postMessage(
					{ type: "BUYED_PREMIUM_WHEEL", data: hash.boc },
					"*"
				);
			}

			tonConnectUI.disconnect();
			onClose();
		} catch (e) {
			console.error(e);
		}
	};

	const aptosTransFer = async () => {
		const bnbUsdtPriceResponse = await fetch(
			"https://api.binance.com/api/v3/ticker/price?symbol=APTUSDT"
		);
		const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
		const dataDecode: any = await decodeJwt(dataPros!);
		console.log({ dataDecode });
		const accountCoinsData: any = await devnetClient.getAccountCoinsData({
			accountAddress: getAptosClient!.accountAddress.toString(),
		});
		console.log({ accountCoinsData });
		if (
			accountCoinsData.length < 1 ||
			(accountCoinsData.length > 0 &&
				convertOctasToApt(accountCoinsData[0].amount) <
					Number(dataDecode!.Price! / bnbUsdtPriceData.price))
		) {
			setInsufficientCoin(true);
		} else {
			setShowLoading(true);
			const txn = await devnetClient.transaction.build.simple({
				sender: getAptosClient!.accountAddress,
				data: {
					function: "0x1::coin::transfer",
					typeArguments: [APTOS_COIN],
					functionArguments: [
						"0xee8462eadbbb0430776829e5ec5a465cf30d1617dc398416e4573c8ebfaaa485",
						convertAptToOctas(
							Number(
								(
									dataDecode!.Price! / bnbUsdtPriceData.price
								).toFixed(2)
							)
						),
					],
				},
			});
			const committedTxn = await devnetClient.signAndSubmitTransaction({
				signer: getAptosClient!,
				transaction: txn,
			});

			await devnetClient.waitForTransaction({
				transactionHash: committedTxn.hash,
			});
			var iframe: any = document.getElementById("isLandIframe");
			if (isBUY_LEGACY) {
				iframe.contentWindow.postMessage(
					{ type: "BOUGHT_LEGACY", data: committedTxn.hash },
					"*"
				);
			} else {
				iframe.contentWindow.postMessage(
					{ type: "BUYED_PREMIUM_WHEEL", data: committedTxn.hash },
					"*"
				);
			}

			setShowLoading(false);
			onClose();
		}
	};

	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<img
					src={walletIcon}
					width={171}
					alt="slime"
					className={style.popup__icon}
				/>
				<img
					src={buttonCancel}
					width={36}
					height={38}
					alt="button cancel"
					className={style.button__close}
					onClick={onClose}
				/>
			    <p className={style.paymentChoose}>Choose payment method</p>
				<div className={`${style.buttonContainer}`}>
					<p
						className={`${style.button} ${style.metamask} active-btn`}
						onClick={() => aptosTransFer()}
					>
						<img src={AptosLogo} alt="Aptos" /> APTOS
					</p>
					<p
						className={`${style.button} ${style.ton} active-btn`}
						onClick={async () => {
							await tonConnectUI.openModal();
						}}
					>
						<img src={TonImg} alt="TON" /> TON{" "}
					</p>
				</div>
			</div>
			<div className={`${style.modalOverlay}`}></div>

			{insufficientCoin && (
				<ModalCoppyAddress
					addressWallet={getAptosClient!.accountAddress.toString()}
					onClose={() => setInsufficientCoin(false)}
				/>
			)}

			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
		</div>
	);
}
